import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';
import { sendMessage, newChat, getImagePathForPersonna, getPersonaDetails, getInferenceChat, deleteChat } from './messaging';
import * as signalR from '@microsoft/signalr';
import { Avatar, List, Input, Button, Space, Image, Spin, Popconfirm } from 'antd';
import { AudioOutlined, LoadingOutlined, SoundOutlined, HeatMapOutlined, DeleteOutlined, PlusOutlined, StopOutlined, BugOutlined } from '@ant-design/icons';
import ConfigurationManager from './components/ConfigurationManager';
/*import PlaybackManager from './PlaybackManager';*/
/*import WakeWord from './wakeword'*/

const { Search } = Input;

//Wake on keyword
//https://github.com/Picovoice/porcupine/blob/master/LICENSE
//https://github.com/linto-ai/WebVoiceSDK/blob/master/tests/with-bundler/index.js -- looks good

export default function SydneyChatHub({ playbackManager, tokenManager, mic, persona, loadedChatId, loadedChatMessages, showDebugInfo, costsUpdated, updateChatList }) {    
    const [connection, setConnection] = useState(null);
    const [latestUserInput, setLatestInput] = useState(null);
    const [messages, setMessages] = useState([]);
    const [logMessages, setlogMessages] = useState([]);
    const [sortedMessages, setSortedMessages] = useState([]);   //Needed to sort the messages by timestamp, as they were ariving out of order.    
    const [chatId, setChatId] = useState(0);
    const [speakerOn, setSpeakerOn] = useState(true);
    const isProcessing = useRef(false);
    const isSpeakerOn = useRef(true);
    const [listeningOn, setListeningOn] = useState(false);
    const [hasPersona, setHasPersona] = useState(false);
    const [imagePath, setImagePath] = useState(false);
    const [chatCost, setChatCost] = useState(null);
    const [commandsOn, setCommandsOn] = useState(false);
    const [searchInProgress, setSearchInProgress] = useState(false);
    const queue = useRef([]);
    const myRef = useRef(null);    
    const [continuousListeningOn, setContinuousListeningOn] = useState(false);
    const [currentPersonaDetail, setCurrentPersonaDetail] = useState(null);    
    const [imgSrc, setImgSrc] = useState({});
    const userInputBox = useRef(null);
    const [showDebugConsole, setShowDebugConsole] = useState(false);    
    const MAX_TICKS = 9223372036854775806n;

    //have a variable to hold the previouslyprossed persona, so we can detect when it changes
const prevPersona = useRef(null);

    useEffect(() => {
        setShowDebugConsole(false);
    }, []);

    useEffect(() => {
        console.defaultLog = console.log.bind(console);
        console.defaultError = console.error.bind(console);
        console.defaultWarn = console.warn.bind(console);
        console.defaultDebug = console.debug.bind(console);
        console.defaultInfo = console.info.bind(console);

        console.log = function () {
            console.defaultLog.apply(console, arguments);

            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }
        console.info = function () {
            console.defaultLog.apply(console, arguments);

            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }

        console.error = function () {
            // default &  console.error()
            console.defaultError.apply(console, arguments);
            // new & array data
            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }

        console.warn = function () {
            console.defaultWarn.apply(console, arguments);

            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }
        console.debug = function () {
            // default &  console.debug()
            console.defaultDebug.apply(console, arguments);
            setlogMessages(
                logMessages => [...logMessages, Array.from(arguments)]
            );
        }

        return () => {
            console.debug = console.defaultDebug;
            console.warn = console.defaultWarn;
            console.error = console.defaultError;
            console.log = console.defaultLog;
        };
    }
        , []);

    useEffect(() => {
        setChatId(loadedChatId);
    }, [loadedChatId]);

    const handleCosts = useCallback((costsForChatId, costs) => {
        if (costsForChatId === chatId) {
            //format the cost as dollars and cents with 2 decimal places
            var cost = costs.costForChat.toFixed(3);
            setChatCost(cost);
            costsUpdated(costs);
        }
    }, [chatId, costsUpdated]);

    const handleMessage = useCallback(async (messageChatId, message) => {

        if (message.debugLogId !== null &&
            message.debugLogId !== undefined &&
            message.debugLogId !== "") {
            var debugData = await getInferenceChat(await tokenManager.getAADToken(), message.debugLogId);
            queue.current.push({ ...message, debugData: debugData, chatId: messageChatId });
        }
        else {
            queue.current.push({ ...message, debugData: debugData, chatId: messageChatId });
        }

    }, [tokenManager]);

    const handleUpdateChatList = useCallback((newChatList) => {
        updateChatList(newChatList);
    }, [updateChatList]);



    useEffect(() => {
        if (tokenManager !== null) {
            const newConnection = new signalR.HubConnectionBuilder()
                .withAutomaticReconnect()
                .withUrl(`${ConfigurationManager.getApiUrl()}chatHub`, {
                    accessTokenFactory: async () => {
                        var aadToken = tokenManager.getAADToken()
                        return aadToken;
                    }
                })
                .configureLogging(signalR.LogLevel.Debug)
                .build();

            setConnection(newConnection);
        }
        else {
        }
    }, [tokenManager]);

    useEffect(() => {
        if (connection) {
            if (connection.state === 'Disconnected' && !connection.connectionStarted) {
                connection.start()
                    .then(() => console.log('SignalR Connected'))
                    .catch(console.error);
            }
        }
    }, [connection, handleCosts, handleMessage, handleUpdateChatList, chatId]);

    useEffect(() => {
        if (connection) {
            if (connection.state === 'Connected') {
                
                connection.off('ReceiveMessage', handleMessage);
                connection.off('ReceiveCosts', handleCosts);
                connection.off('ReceiveAllChats', handleUpdateChatList);

                connection.on('ReceiveMessage', handleMessage);
                connection.on('ReceiveCosts', handleCosts);
                connection.on('ReceiveAllChats', handleUpdateChatList);
            }
        }
    }, [connection, handleCosts, handleMessage, handleUpdateChatList, chatId]);

    const dedupeMessages = useCallback((messages) => {
        var dedupedMessages = [];
        messages.forEach((message) => {
            var found = false;
            dedupedMessages.forEach((dedupedMessage) => {
                if (String(dedupedMessage.id) === String(message.id) && message.id !== null) {
                    found = true;
                }
            });

            if (!found) {
                dedupedMessages.push(message);
            }
        });

        return dedupedMessages;
    }, []);

    const integrateMessages = useCallback((currentMessages, newMessages) => {
        //clone the messages array
        var messagesCopy = [...currentMessages];


        for (var newMessageIndex = 0; newMessageIndex < newMessages.length; newMessageIndex++) {
            var newMessage = newMessages[newMessageIndex];

            var index = locateMessageInOldList(newMessage);

            if (index !== null) {
                var existingMessage = messagesCopy[index];

                //if it's got more info than the existing message, then replace it
                if (existingMessage != null && newMessage != null && (existingMessage.friendlySummary === null || existingMessage.friendlySummary === undefined || existingMessage.friendlySummary === "") &&
                    (newMessage.friendlySummary !== null && newMessage.friendlySummary !== undefined && newMessage.friendlySummary !== "")) {
                    messagesCopy.splice(index, 1, newMessage);
                }

            }
            else {
                messagesCopy = messagesCopy.concat(newMessage);
            }
        }

        function locateMessageInOldList(newMessage) {
            var syncIdIndex = null;
            for (var index = 0; index < messages.length; index++) {
                var message = messages[index];
                if (String(message.id).toString() === String(newMessage.id).toString()) {
                    syncIdIndex = index;
                    break;
                }
            }

            return syncIdIndex;
        }


        //reverse search starting at the last roleName==="user" and remove only the previous messages that has a null id 
        //var lastUserMessageIndex = -1;

        //for (var index = messagesCopy.length - 1; index >= 0; index--) {
        //    var message = messagesCopy[index];

        //    if (message.roleName === "user" && lastUserMessageIndex === -1) {
        //        lastUserMessageIndex = index;
        //    }
        //    else {
        //        if (message.id === null && lastUserMessageIndex !== -1) {
        //            messagesCopy.splice(index, 1);
        //            break;
        //        }
        //    }
        //}

        messagesCopy = dedupeMessages(messagesCopy);

        return (messagesCopy);
    },[dedupeMessages, messages]);    

   function htmlEnc(s) {
        return s.replace(/&/g, '&amp;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;')
            .replace(/'/g, '&#39;')
            .replace(/"/g, '&quot;');
    }

    const processQueue = useCallback(() => {
        try {
            if (queue.current.length > 0 && chatId !== null && chatId !== undefined) {
                const message = queue.current[0];

                queue.current = queue.current.slice(1);

                if (message.chatId == null || message.chatId.toString() !== chatId.toString()) {
                    processQueue();
                    return;
                }

                setMessages(prevMessages => integrateMessages(prevMessages, [message]));


                if (isSpeakerOn.current) {
                    switch (message.roleName) {
                        case "assistant":
                            setSearchInProgress(false);
                            break;
                        case "command":
                            if (message.content.toLowerCase() === htmlEnc("<performsearch>")) {
                                setSearchInProgress(true);

                                if (speakerOn) {
                                    playbackManager.toVoice("Searching", function () {
                                        processQueue();
                                    });
                                }
                            }
                            break;
                        default:
                            processQueue();
                            break;
                    }
                }
                else {
                    processQueue();
                }
            }
        }
        catch (e) {
            console.error("Error when processing: " + e);
        }
        finally {
            isProcessing.current = false;
        }
    }, [chatId, integrateMessages, playbackManager, speakerOn]);


    const processQueueRef = useRef(processQueue);
    useEffect(() => {
        processQueueRef.current = processQueue;
    }, [processQueue]);  

    useEffect(() => {
        let intervalId;
        if (connection) {
            intervalId = setInterval(() => {
                if (!isProcessing.current && chatId !== null && persona !== null) {
                    isProcessing.current = true;
                    processQueueRef.current(chatId); 
                }
            }, 100);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [continuousListeningOn, chatId, persona, connection, processQueue]);

    

    useEffect(() => {    
        //add a new message at the start of the list called intro, this has the description contents of the current persona in it.
        if (currentPersonaDetail !== null && currentPersonaDetail !== undefined
            && currentPersonaDetail.Description !== null && currentPersonaDetail.Description !== undefined
            && currentPersonaDetail.Description !== ""
        ) {
            var introMessage = { id: null, timestamp: 0, content: currentPersonaDetail.Description, roleName: "intro" };
            setSortedMessages([introMessage, ...(messages.sort((a, b) => a.timeStamp - b.timeStamp))]);
        }
        else {
                setSortedMessages(messages.sort((a, b) => a.timeStamp - b.timeStamp));
        }

    }, [currentPersonaDetail, messages]);

    const doCancelSynth = useCallback(async () => {
        if (playbackManager) {
            await playbackManager.stop();
        }
    }, [playbackManager]);

    function removeTags(htmlString, tag) {
        // Create a new DOM element to hold the HTML string
        var container = document.createElement('div');
        container.innerHTML = htmlString;

        // Find all tag elements in the container
        var tags = container.querySelectorAll(tag);

        // Loop through the img elements and remove them from the container
        for (var i = 0; i < tags.length; i++) {
            tags[i].parentNode.removeChild(tags[i]);
        }

        // Return the remaining HTML as a string
        return container.innerHTML;
    }

   const doShowReady = (state) => {
        setListeningOn(state);
        if (state === false) {
            //wakeWordRef.current.resume();
        }
    }

    const doSendMessage = useCallback(async (message) => {
        if (connection) {
            //get the timestamp of the last message

            var timestamp = 0;
            if (sortedMessages.length > 0) {
                const filteredList = sortedMessages.filter(message => message.roleName !== "waiting");
                const lastMessage = filteredList[filteredList.length - 1];

                if (lastMessage !== null && lastMessage !== undefined) {
                    timestamp = lastMessage.timestamp;

                    if (timestamp === null || timestamp === undefined || isNaN(timestamp)) {
                        timestamp = 0;
                    }
                }
                else
                    timestamp = 0;
            }

            setMessages(
                messages => [...messages, { id: null, timestamp: timestamp + 1, content: message, roleName: "user" }]
            );

            setLatestInput("");

            setMessages(
                messages => [...messages, { id: null, timestamp: MAX_TICKS, content: "", roleName: "waiting" }]
            );
            
            await doCancelSynth();
            try {
                var chatResponse = await sendMessage(await tokenManager.getAADToken(), persona, chatId, message);

                if (chatId !== chatResponse.chatId) {
                    setChatId(chatResponse.chatId);
                }

                if (chatResponse && chatResponse.messages.length > 0) {
                    setMessages(currentMessages => integrateMessages(currentMessages.filter(message => message.roleName !== "waiting"), chatResponse));
                    if (chatResponse.messages[chatResponse.messages.length - 1].roleName === "assistant") {
                        if (speakerOn) {
                            var newMessage = chatResponse.messages[chatResponse.messages.length - 1];
                            if (newMessage.success) {
                                var replyText = newMessage.content;
                                replyText = removeTags(replyText, 'img');
                                replyText = removeTags(replyText, 'searchids');


                                await playbackManager.toVoice(replyText, async () => {
                                    if (continuousListeningOn) {
                                        await doCancelSynth();
                                        await mic(doShowReady, doSendMessage, persona, chatId)
                                    }
                                });

                            }
                        }
                    }
                }
            }
            catch (e) {
                console.error("Error sending message: " + e);
                setMessages(currentMessages => currentMessages.filter(message => message.roleName !== "waiting"));
            }
        }

    }, [connection, sortedMessages, doCancelSynth, MAX_TICKS, tokenManager, persona, chatId, integrateMessages, speakerOn, playbackManager, continuousListeningOn, mic]);

    // handle what happens on key press
    const handleKeyPress = useCallback((event) => {
        if (event.altKey === true && (event.key === 't' || event.key === 'T')) {
            startListening();
        }

        async function startListening() {
            if (persona !== null && chatId !== null) {
                queue.current.push({ message: "Yeah?", roleName: 'assistant' });

                setContinuousListeningOn(true);

                mic(doShowReady, doSendMessage, persona, chatId).catch(error => {
                    console.error('Error starting the microphone:', error);
                });
            }
        }
    }, [chatId, doSendMessage, mic, persona]);

        

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);


    const voiceComplete = useCallback(async () => {
        processQueue();

        if (continuousListeningOn) {
            await doCancelSynth();
            await mic(doShowReady, doSendMessage, persona, chatId)
        }
    }, [processQueue, continuousListeningOn, doCancelSynth, mic, doSendMessage, persona, chatId]);

    const onNewChat = useCallback(async () => {
        setContinuousListeningOn(false);
        await doCancelSynth();

        if (persona !== undefined && persona !== null) {

            setChatId(null);
            setChatCost(null);
            


            setMessages(
                [{ id: null, timestamp: MAX_TICKS, content: "", roleName: "waiting" }]
            );

            var newChatData = await newChat(await tokenManager.getAADToken(), persona);

            if (newChatData !== undefined && newChatData !== null && newChatData !== null) {
                var chatId = newChatData.chatId;
                setChatId(chatId);

                setMessages(currentMessages => integrateMessages(currentMessages.filter(message => message.roleName !== "waiting"), newChatData.messages));

                if (chatId !== null && chatId !== undefined) {
                    if (speakerOn) {
                        if (newChatData.messages.length >= 0) {
                            var lastMessage = newChatData.messages[newChatData.messages.length - 1];

                            if (lastMessage !== null && lastMessage !== undefined && lastMessage.roleName === "assistant") {
                                await playbackManager.toVoice(lastMessage.content, voiceComplete);
                            }
                        }
                    }
                }
            }
            else {
                setMessages([]);
                setChatId(null);
            }
        }
    }, [doCancelSynth, persona, MAX_TICKS, tokenManager, integrateMessages, speakerOn, playbackManager, voiceComplete]);

    const onPersonaChange = useCallback(async (currentPersona) => {
        if (prevPersona.current !== currentPersona) {
            prevPersona.current = currentPersona;
            if (currentPersona !== null && currentPersona !== undefined) {

                const fetchData = async (currentPersona) => {
                    setMessages([]);
                    await onNewChat(currentPersona);
                };

                fetchData(persona);

                const getImagePath = async (currentPersona) => {
                    return await getImagePathForPersonna(await tokenManager.getAADToken(), currentPersona);
                };

                getImagePath(currentPersona)
                    .then(path => {
                        setImagePath(path)
                    }
                    );
            }
            setHasPersona(currentPersona !== null);
        }
    }, [onNewChat, persona, tokenManager]);

    useEffect(() => {
        onPersonaChange(persona);
    }, [onPersonaChange, persona]);


    useEffect(() => {
        setMessages(loadedChatMessages);
    }, [loadedChatMessages]);

    useEffect(() => {
        if (tokenManager !== undefined && persona !== undefined && tokenManager !== null && persona !== null) {
            tokenManager.getAADToken().then((aadToken) => {
                getPersonaDetails(aadToken, persona).then((data) => {
                    setCurrentPersonaDetail(data);
                });
            });
        }
    }, [tokenManager, persona]);

    const onCancelSynth = async () => {
        setContinuousListeningOn(false);

        doCancelSynth();
    };

    const onSendMessage = async () => {
        await doSendMessage(latestUserInput);
    };

    function onUpdateUserInputField(e) {
        setLatestInput(e.target.value);
    }

    function arrayBufferToBase64(buffer) {
        let binary = '';
        const bytes = [].slice.call(new Uint8Array(buffer));

        bytes.forEach((b) => (binary += String.fromCharCode(b)));

        return window.btoa(binary);
    }

    function renderExtra(message, listHtml, imageUrls) {
        getToken().then((aadToken) => {
            imageUrls.map((url) => {
                let fetchUrl = "";
                let corsMode = 'cors';

                if (url.startsWith('https') || url.startsWith('http')) {
                    if (url.startsWith('http')) {
                        fetchUrl = 'https' + url.substring(4);
                    }
                    else {
                        fetchUrl = url;
                    }

                    corsMode = 'no-cors';
                }
                else {
                    fetchUrl = `${ConfigurationManager.getApiUrl()}Image/${imagePath}/${url.replace(/^\/+/, '')}`;
                }

                if (!imgSrc[url]) {

                    fetch(fetchUrl, {
                        method: "GET",
                        headers: { Authorization: 'Bearer ' + aadToken },
                        mode: corsMode,
                    }).then((response) => {
                        if (response.ok) {
                            response.arrayBuffer().then((buffer) => {
                                const base64Flag = `data:${response.headers.get('content-type')};base64,`;
                                const imageStr = arrayBufferToBase64(buffer);
                                setImgSrc((prevState) => ({ ...prevState, [url]: base64Flag + imageStr }));
                            });
                        }
                        else {
                            console.warn("Image failed to load: " + fetchUrl);
                        }
                    }).catch(error => console.warn("Image failed to load: " + fetchUrl));

                }

                return "";
            });
        });

        return (<div className="item-content">
            {listHtml && (
                <>
                    <div className="lists-container" dangerouslySetInnerHTML={{ __html: listHtml }}></div>
                    {message.debugLogId !== null && message.debugLogId !== undefined && message.debugLogId !== "" && commandsOn ?
                        <div>

                            <Button type="primary" onClick={async () => {
                                var data = await getInferenceChat(await tokenManager.getAADToken(), message.debugLogId);

                                //trigger download of the file
                                var element = document.createElement('a');
                                element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(data.ChatData));
                                element.setAttribute('download', "inference.json");
                                element.style.display = 'none';
                                document.body.appendChild(element);
                                element.click();
                                document.body.removeChild(element);

                            }}>Download Chat</Button>

                            {
                                //if the debugLogId is not null then show the inference data
                                message.debugData !== null && message.debugData !== undefined && commandsOn &&
                                <div className="inference-data">
                                    <h3>Inference Data</h3>
                                    {
                                        //render the data 
                                        <div className="inference-data-item">
                                            <table>
                                                <thead>
                                                    <tr></tr>
                                                    <td ></td>
                                                    <td></td>
                                                    <tr></tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        Object.keys(message.debugData).map((key, index) => {
                                                            if (key !== "ChatData")
                                                                return (
                                                                    <tr key={index}>
                                                                        <td className="inference-data-item-row-item">{key}</td>
                                                                        <td className="inference-data-item-row-item">{JSON.stringify(message.debugData[key], null, 2)}</td>
                                                                    </tr>
                                                                );
                                                            else return "";
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        : <></>
                    }
                </>
            )}

            {imageUrls && imageUrls.length > 0 && (
                <div className="imgs-container">
                    <Image.PreviewGroup>
                        <Space>
                            {imageUrls.map((url, index) => {
                                return (
                                    <div className="image-backdrop" key={index}>
                                        <Image width={200} src={imgSrc[url]} alt="" className="chat-image" />
                                    </div>
                                );
                            })}
                        </Space>
                    </Image.PreviewGroup>
                </div>
            )}
        </div>);
    }



    function extractAllImageSrcs(htmlString) {
        // Create a new DOM element to hold the HTML string
        var container = document.createElement('div');
        container.innerHTML = htmlString;

        // Find all img elements in the container
        var imgs = container.querySelectorAll('img');

        // Create an array to hold the src URLs
        var srcs = [];

        // Loop through the img elements and add their src attributes to the array
        for (var i = 0; i < imgs.length; i++) {
            srcs.push(imgs[i].getAttribute('src'));
        }

        return srcs;
    }


    async function onDeleteChat() {
        var aadToken = await tokenManager.getAADToken();
        await deleteChat(aadToken, persona, chatId);
        setChatId(null);
    }

    //get the user agent
    const userAgent = navigator.userAgent.toLowerCase();
    const iPad = ((userAgent.match(/(iPad)/) /* iOS pre 13 */ || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1) /* iPad OS 13 */));

    const listeningIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

    //var aadToken = null;

    async function getToken() {
        return await tokenManager.getAADToken();
    }

    //getToken();




    var searchSuffix = (
        !iPad &&
        <Button disabled={!hasPersona} onClick={
            async (e) => {
                await doCancelSynth();
                var text = await mic(doShowReady, null, persona, chatId)
                await doSendMessage(text);
            }
        }

            className="speak-button" >
            <AudioOutlined
                style={{
                    fontSize: 20,
                    color: '#1677ff',
                }}
            />
        </Button>
    );



    useLayoutEffect(() => {
        var objDiv = document.getElementById("chatMessageContainer");

        if (objDiv != null) {
            objDiv.scrollBy(0, 100000);
        }
    });




    var chatbox = document.getElementById("chatbox");
    var chatMessageContainer = document.getElementById("chatMessageContainer");

    if (chatbox) {        
        // Put the body relative
        document.body.style.position = 'relative';
        let marginTop = parseInt(window.getComputedStyle(document.body).marginTop);

        // My toolbar (in my case, a div with an input inside to make a chat)
        chatbox.style.position = 'absolute';

        // Events (touchmove on mobile, because the scroll event doesn't work well)
        window.addEventListener("scroll", resizeHandler);
        window.addEventListener("touchmove", resizeHandler);
        window.visualViewport.addEventListener("resize", resizeHandler);

        resizeHandler();

        function resizeHandler() {
            chatbox.style.top = (window.scrollY + window.visualViewport.height - marginTop - 160) + 'px';
            chatbox.style.right = "0px";
            chatbox.style.left = "60px";
            chatbox.style.bottom = "0px";

            //set the window Y scroll position to 0
            window.scrollTo(0, 0);
            chatMessageContainer.style.height = (window.visualViewport.height - marginTop - 190) + 'px';
        }
    }


    return (
        <div className="chathub-control">
            <div className="left-vertical-control-bar">
                <div className="left-side-button chat-delete">
                    <Popconfirm
                        placement="right"
                        title="Are you sure you want to delete this chat?"
                        description="Delete chat"
                        onConfirm={onDeleteChat}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ fontSize: 20 }}
                    >
                        <Button
                            type="text"
                            icon={<DeleteOutlined style={{ fontSize: 26 }} />}

                            disabled={chatId === null || chatId === -1 || !hasPersona}
                            style={{
                                width: 60,
                                height: 60,
                            }}
                        />
                    </Popconfirm>
                </div>

                <div className="left-side-button new-chat">
                    <Popconfirm
                        placement="right"
                        title="Are you sure you want to start a new chat?"
                        description="New chat"
                        onConfirm={onNewChat}
                        okText="Yes"
                        cancelText="No"
                        okButtonProps={{ fontSize: 20 }}
                    >
                        <Button
                            type="text"
                            icon={<PlusOutlined style={{ fontSize: 26 }} />}
                            disabled={!hasPersona}
                            style={{
                                width: 60,
                                height: 60,
                            }}
                        />
                    </Popconfirm>
                </div>


                <div className="left-side-button sound-on-off">
                    <Button
                        type="text"
                        icon={speakerOn ? <SoundOutlined style={{ color: 'black', fontSize: 26 }} /> : <SoundOutlined style={{ color: 'grey', fontSize: 26 }} />}
                        onClick={() => setSpeakerOn(!speakerOn)}
                        style={{
                            color: '#ffffff',
                            width: 60,
                            height: 60,
                        }}
                    />
                </div>



                {persona && showDebugInfo &&
                    <>
                        <div className="left-side-button debug-on-off">
                            <Button
                                type="text"
                                icon={commandsOn ? <HeatMapOutlined style={{ color: 'black', fontSize: 26 }} /> : <HeatMapOutlined style={{ color: 'grey', fontSize: 26 }} />}
                                onClick={() => setCommandsOn(!commandsOn)}
                                style={{
                                    color: '#ffffff',
                                    width: 60,
                                    height: 60,
                                }}
                            />
                        </div>
                    </>


                }


                {
                    <div className="left-side-button debug-console">
                        <Button
                            type="text"
                            icon={showDebugConsole ? <BugOutlined style={{ color: 'black', fontSize: 26 }} /> : <BugOutlined style={{ color: 'grey', fontSize: 26 }} />}
                            onClick={() => setShowDebugConsole(!showDebugConsole)}
                            style={{
                                color: '#ffffff',
                                width: 60,
                                height: 60,
                            }}
                        />
                    </div>
                }



                <div className="left-side-button stop-playback">
                    <Button
                        type="text"
                        icon={<StopOutlined style={{ fontSize: 26 }} />}
                        disabled={chatId === null || chatId === -1 || !hasPersona}
                        onClick={onCancelSynth}
                        style={{
                            width: 60,
                            height: 60,
                        }}
                    />
                </div>

            </div>
            <Spin className="listening" spinning={listeningOn} indicator={listeningIcon} tip="Listening...">
                <div className="chat-messages" id="chatMessageContainer">
                    {chatId != null &&
                        <>
                            <List
                                itemLayout="horizontal"
                                dataSource={sortedMessages}
                                renderItem={(message, index) => {

                                    var messageText = message.content;

                                    let listHtml = "";
                                    var mainmessage = "";

                                    mainmessage = messageText;


                                    var srcTags = extractAllImageSrcs(messageText);
                                    mainmessage = removeTags(mainmessage, 'img');
                                    mainmessage = removeTags(mainmessage, 'search');

                                    switch (String(message.roleName).toLowerCase()) {
                                        case "assistant":
                                            //encode anything inside a <code> tag

                                            //do the regex spearately and extract the contents of the code tag
                                            var codeRegex = /<code>(.*?)<\/code>/gs;
                                            var codeMatches = messageText.match(codeRegex);
                                            if (codeMatches !== null && codeMatches !== undefined) {
                                                codeMatches.forEach((match) => {
                                                    var code = match.replace(/<\/?code>/g, '');
                                                    mainmessage = mainmessage.replace(match, "<code>" + htmlEnc(code) + "</code>");
                                                });
                                            }

                                            mainmessage = mainmessage.replaceAll(/\r\n|\r|\n/g, "<br/>");
                                            break;
                                        case "user":
                                            mainmessage = htmlEnc(mainmessage);
                                            mainmessage = mainmessage.replaceAll(/\r\n|\r|\n/g, "<br/>");
                                            break;
                                        default:
                                            break;
                                    }


                                    if (commandsOn && message.agentTypeString !== undefined && message.agentTypeString !== null) {
                                        mainmessage += "<br/>Generated by:" + message.agentTypeString + "<br/>";
                                    }

                                    if (commandsOn) {
                                        mainmessage += `<div>id:${message.id}</div>`
                                    }

                                    if (!commandsOn) {
                                        mainmessage = removeTags(mainmessage, 'searchids');
                                        mainmessage = removeTags(mainmessage, 'potentialsolution');
                                    }

                                    const role = String(message.roleName).toLowerCase();


                                    if (mainmessage !== "" || listHtml !== "" || srcTags.length !== 0 || role === "waiting" || role === "error") {
                                        switch (role) {
                                            case "intro":
                                                return (
                                                    <List.Item key={index} className="intro-li">
                                                        {renderExtra(message, mainmessage, srcTags)}
                                                    </List.Item>
                                                )                                                
                                            case "command":
                                                if (messageText.toLowerCase() === htmlEnc("<performsearch>")) {                                                    
                                                    return (<List.Item key={index} className="message-search">
                                                        {searchInProgress && sortedMessages.slice(
                                                            index + 1).filter((item) => item.roleName === "command" && item.content.toLowerCase() === htmlEnc("<performsearch>")).length === 0 &&
                                                            <div >
                                                                <img src="/img/searching.png" alt="searching" className="search-icon"></img>
                                                                <div className="search-running-text">Searching</div>
                                                            </div>
                                                        }
                                                        {(!searchInProgress || sortedMessages.slice(index + 1).filter((item) => item.roleName === "command" && item.content.toLowerCase() === htmlEnc("<performsearch>")).length !== 0) &&
                                                            <div >
                                                                <img src="/img/search-complete.png" alt="search-complete" className="search-icon"></img>
                                                                <div className="search-complete-text">Search Complete</div>
                                                            </div>
                                                        }
                                                        <div className="step-list">
                                                            <ol >
                                                                {
                                                                    sortedMessages.slice(index + 1, sortedMessages.indexOf(
                                                                        sortedMessages.slice(sortedMessages.indexOf(message) + 1).filter((item) => item.roleName === "assistant")[0] //Stop at the first response after this command
                                                                    ) - 1
                                                                    ).filter((item) => item.roleName === "info" && item.friendlySummary !== null && item.friendlySummary !== undefined)
                                                                        .map((item) => {
                                                                            return (<li>{item.friendlySummary}</li>);
                                                                        })
                                                                }
                                                            </ol>
                                                        </div>
                                                    </List.Item>);
                                                }

                                                if (commandsOn && mainmessage.replaceAll(/\s/g, '').length) {
                                                    mainmessage = mainmessage.replaceAll(htmlEnc("}\r\n"), "}<br/>\r\n");


                                                    return (
                                                        <List.Item key={index} className="command-li">
                                                            <Avatar size={64} src={'CommandRun.png'} className="command-avatar" />
                                                            {renderExtra(message, mainmessage, srcTags)}
                                                        </List.Item>);
                                                }
                                                break;
                                            case "user":
                                                return (
                                                    <List.Item key={index} className="user-li">
                                                        <Avatar size={80} src={'img/UserAvatar.png'} className="user-avatar" />
                                                        {renderExtra(message, mainmessage, srcTags)}
                                                    </List.Item>

                                                )
                                            case "assistant":

                                                return (
                                                    <List.Item key={index} className="assistant-li">
                                                        <Avatar size={80} src={'img/AshleyAvatar.png'} className="sydney-avatar" />
                                                        {renderExtra(message, mainmessage, srcTags)}
                                                    </List.Item>
                                                )
                                            case "info":
                                                if (commandsOn && mainmessage.replaceAll(/\s/g, '').length) {
                                                    mainmessage = mainmessage.replaceAll(htmlEnc("}\r\n"), "}<br/>\r\n");

                                                    return (
                                                        <List.Item key={index} className="info-li">
                                                            <Avatar size={64} src={'info.png'} className="command-avatar" />
                                                            {renderExtra(message, mainmessage ? String(mainmessage) + " " + message.friendlySummary : message.friendlySummary, srcTags)}
                                                        </List.Item>);
                                                }
                                                break;
                                            case "error":
                                                return (
                                                    <List.Item key={index} className="error-li" title="error">
                                                        <Avatar size={80} src={'img/error.png'} className="sydney-avatar" />
                                                        {renderExtra(message, commandsOn ? String(message.detailedError) : String(message.friendlyErrorTextToDisplay), srcTags)}                                  
                                                    </List.Item>);
                                                
                                            case "waiting":
                                                return (
                                                    <List.Item key={index} className="assistant-li">
                                                        <LoadingOutlined className="waiting-spinner" />
                                                    </List.Item>);                                                
                                            default:
                                                break;
                                        }
                                    }
                                }
                                }
                            />
                        </>
                    }
                    <div ref={myRef}  >
                    </div>
                </div>
            </Spin>

            <div className="chat-hub-input-area" >
                <div className="chatbox" id="chatbox">
                    <Search ref={userInputBox} placeholder="Please ask your question" onChange={onUpdateUserInputField} value={latestUserInput} disabled={!hasPersona} enterButton="Ask" size="large" onSearch={onSendMessage} suffix={searchSuffix} className={(iPad ? "userInput userInput-iPad" : "userInput userInput-desktop")} />
                </div>

                {
                    //conditionally render a div if the persona has the showChatCost property set to true
                    persona && currentPersonaDetail && currentPersonaDetail.ShowChatCost && <div className="chat-cost">Chat Cost: ${chatCost}</div>
                }    
            </div>
            {                
                showDebugConsole &&
                <List itemLayout="horizontal" className="debugConsole" dataSource={logMessages}
                    renderItem={(item) => (
                        <List.Item>
                            {item}
                        </List.Item>
                    )}>
                </List>
            }
        </div>
    );
}