class PlaybackManager {
    static #instance;
    player1 = null;
    synthesizer = null;
    id = Math.floor(Math.random() * 1000);
    synthVoice = "en-US-JennyNeural";
    culture = "en-US";
    _speechsdk = null;
    _tokenManager = null;

    constructor(speechsdk, tokenManager) {
        this._speechsdk = speechsdk;
        this.player = null;
        this.synthesizer = null;
        this._tokenManager = tokenManager;

        if (!PlaybackManager.#instance) {
            PlaybackManager.#instance = this;
        }
        PlaybackManager.#instance._tokenManager = tokenManager;
        PlaybackManager.#instance._speechsdk = speechsdk;
        return PlaybackManager.#instance;
    }
    get() {
        return PlaybackManager.#instance;
    }

    setPlayer(playerToSet) {
        //console.info(`>> Setting ${this.id}`);
        this.player1 = playerToSet;
    }

    getPlayer() {
        //console.info(`>> Getting from ${this.id}`);
        return this.player1;
    }

    stop() {
        //console.info(`>> Stopping on ${this.id}`);

        if (this.player1 !== null && this.player1 !== undefined) {
            this.player1.pause();
        }

        if (this.synthesizer) {
            this.synthesizer.close();
            this.synthesizer = null;
        }
    }

    testSpeech() {
        var synthesisText = "Hello";

        // if we got an authorization token, use the token. Otherwise use the provided subscription key
        var speechConfig;

        speechConfig = this._speechsdk.SpeechConfig.fromSubscription("3404cdac7f9645b8a2f8a3727f504603", "westus");
        speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
        speechConfig.speechSynthesisOutputFormat = "Audio24Khz160KBitRateMonoMp3";

        var player = new this._speechsdk.SpeakerAudioDestination();

        var audioConfig = this._speechsdk.AudioConfig.fromSpeakerOutput(player);

        var synthesizer = new this._speechsdk.SpeechSynthesizer(speechConfig, audioConfig);


        const complete_cb = function (result) {
            synthesizer.close();
            synthesizer = undefined;
        };
        const err_cb = function (err) {
            synthesizer.close();
            synthesizer = undefined;
        };


        //if (isSsml.checked) {
        //    synthesizer.speakSsmlAsync(synthesisText,
        //        complete_cb,
        //        err_cb);
        //} else {
        synthesizer.speakTextAsync(synthesisText,
            complete_cb,
            err_cb);
        // }
    };


    async toVoice(text, callback) {
        console.info("Speaking: " + text);


        this.setPlayer(new this._speechsdk.SpeakerAudioDestination());


        this.getPlayer().onAudioEnd = async function (_) {
            await callback();
        };

        if (this.synthesizer === null) {
            var audioConfig = this._speechsdk.AudioConfig.fromSpeakerOutput(this.getPlayer());
            var tokenObj = await this._tokenManager.getSpeechAPIToken();            
            var speechConfig = this._speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
            speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
            speechConfig.speechSynthesisOutputFormat = "Audio24Khz160KBitRateMonoMp3";
            this.synthesizer = new this._speechsdk.SpeechSynthesizer(speechConfig, audioConfig);
        }

        var wrapped = `<speak version='1.0' xmlns='https://www.w3.org/2001/10/synthesis' xml:lang='${this.culture}'><voice name='${this.synthVoice}'><prosody rate="20%">${text}</prosody></voice></speak>`;
        console.info("Wrapped text: " + wrapped);
        var that = this;

        await this.synthesizer.speakSsmlAsync(wrapped,
            function (result) {

                if (result.reason === that._speechsdk.ResultReason.SynthesizingAudioCompleted) {
                    console.log("synthesis finished.");
                } else {
                    console.error("Speech synthesis canceled, " + result.errorDetails +
                        "\nDid you set the speech resource key and region values?");
                }

                if (that.synthesizer) {
                    that.synthesizer.close();
                    that.synthesizer = null;
                }
            },
            function (err) {
                console.error("err - " + err);
                that.stop();
            });
    }
}

export default PlaybackManager;


//https://stackoverflow.com/questions/70978670/javascript-audio-context-on-safari-ios-15-14-dont-work-anymore
                    /*
                    // Initialize AudioContext  
let audioContext = new AudioContext();  
  
// Event listener for user interaction  
window.addEventListener('touchstart', async function () {  
    // Call your API  
    let response = await fetch("your API url");  
    let data = await response.json();  
  
    // Create an in-memory audio output stream  
    let stream = speechsdk.AudioOutputStream.createPullStream();  
  
    // Create the SpeechSynthesizer using the stream  
    let speechConfig = speechsdk.SpeechConfig.fromSubscription("YourSubscriptionKey", "YourServiceRegion");  
    let audioConfig = speechsdk.AudioConfig.fromStreamOutput(stream);  
    let synthesizer = new speechsdk.SpeechSynthesizer(speechConfig, audioConfig);  
  
    // Use the response data in the speakSsmlAsync method  
    synthesizer.speakSsmlAsync(  
        data,  // Assuming data is your SSML string  
        result => {  
            // Pull audio data from the stream and feed it into the AudioContext  
            let reader = new FileReader();  
            reader.onloadend = function() {  
                let audioData = new Uint8Array(reader.result);  
                let audioBuffer = audioContext.createBuffer(1, audioData.length, audioContext.sampleRate);  
                audioBuffer.getChannelData(0).set(audioData);  
                let source = audioContext.createBufferSource();  
                source.buffer = audioBuffer;  
                source.connect(audioContext.destination);  
                source.start();  
            }  
            reader.readAsArrayBuffer(stream.readAllBytes());  
            synthesizer.close();  
        },  
        error => {  
            console.log(JSON.stringify(error));  
            synthesizer.close();  
        }  
    );  
});  
*/