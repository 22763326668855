import { Select } from 'antd';
import { useEffect, useState } from 'react';





// @azure/msal-browser



const ADGroupsDropdown = ({ onChange, options, value }) => {
    const [filteredOptions, setFilteredOptions] = useState([]);

    useEffect(() => {
        if (value !== undefined && options !== undefined) {
            setFilteredOptions(options.filter((o) => !value.includes(o)));
        }
    }, [value, options]);
    

    return (
        <Select
            onChange={onChange}
            className="ADGroupSelect"
            showSearch
            placeholder="Select an Azure AD Group"
            mode="multiple"
            options={filteredOptions.map((item) => ({
                value: item.key,
                label: item.text,
            }))}
            value={value}            
        />
    );
};

export default ADGroupsDropdown;

