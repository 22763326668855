
class ConfigurationManager {
    static getApiUrl() {              
        return window.env.REACT_APP_API_URL;
    }    

    static getUIUrl() {
        return window.location.origin;    
    }
}

export default ConfigurationManager;

