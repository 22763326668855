//import ant design components
import { Upload, Button, Input } from 'antd';
import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import ConfigurationManager from './ConfigurationManager';
import { UploadOutlined } from '@ant-design/icons';

const ImageUploadModal = ({ msalInstance, personaname, tokenManager, disabled, onSaved, mode, visible }) => {               
    const [isVisible, setIsVisible] = useState(visible);
    const [folderName, setFolderName] = useState('');
    const [apiToken, setApiToken] = useState(null);    



    const formData = new FormData();
    const uploadImageDataProps = {
        data: formData,
        action: `${ConfigurationManager.getApiUrl()}Image/Upload`,
        onChange({ file, fileList }) {
            if (file.status !== 'uploading') {
                console.log(file, fileList);
            }
        },
        defaultFileList: [
        ],
    };

    const parseJsonForImages = {
        data: formData,
        action: `${ConfigurationManager.getApiUrl()}Image/ParseJsonForImages`,
        onChange({ file, fileList }) {
            if (file.status !== 'uploading') {
                console.log(file, fileList);
            }
        },
        defaultFileList: [
        ],
    };


    setInterval(async function () {
        setApiToken(await tokenManager.getAADToken());
    }, 1000*60*10);


    useEffect(() => {
        async function doGetApiToken() {
            setApiToken(await tokenManager.getAADToken());
        }

        doGetApiToken();
    }, [tokenManager]);

    return (
        <div>
            <Button type="primary" onClick={() => setIsVisible(true)} disabled={disabled} >
                Manage Images
            </Button>


            <Modal visible={isVisible} title="Manage Images" onOk={() => { setIsVisible(false) }}>
                <label>
                    Folder Name (optional):
                    <Input placeholder="Folder Name" onChange={(e) => setFolderName(e.target.value)} />
                </label>
                <label>

                    <br />
                    <br />
                Images:&nbsp; 
                <Upload {...uploadImageDataProps} headers={{ 'Authorization': 'Bearer ' + apiToken }} data={{ personaName: personaname, subFolderName: folderName }} multiple listType="picture">                    
                    <Button icon={<UploadOutlined />} style={{ marginRight: 10 }}>
                       Upload
                    </Button>                    
                    </Upload>
                </label>
                <br />

                <label>
                Parse JSon For Images:&nbsp;
                <Upload {...parseJsonForImages} headers={{ 'Authorization': 'Bearer ' + apiToken }} data={{ personaName: personaname, subFolderName: folderName }}>
                    <Button icon={<UploadOutlined />}  style={{ marginRight: 10 }}>
                        Upload
                    </Button>
                    
                </Upload>
                </label>                
            </Modal>
        </div>
    );
};

export default ImageUploadModal;