import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import TokenManager from './TokenManager';
import { getPersonas, getAllChats, getConversation, getPersonaDetails, triggerImportOfBlobDataFile, getInferenceChat } from './messaging';
import { ResultReason } from 'microsoft-cognitiveservices-speech-sdk';
import SydneyChatHub from './SydneyChatHub';
import { Layout, Space, Select, Menu, Tabs, Button, Spin } from 'antd';
/*import { v4 as uuidv4 } from 'uuid';*/
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
/*import { BrowserAuthError } from "@azure/msal-browser";*/
import IndexFileUpload from './components/IndexFileUpload';
/*import Div100vh from 'react-div-100vh'*/
//import type { UploadProps } from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined, UserOutlined
} from '@ant-design/icons';
import PlaybackManager from './PlaybackManager';
import ConfigurationManager from './components/ConfigurationManager';
import PersonaConfigurator from './components/PersonaConfigurator';
/*import DownloadFile from './components/DownloadEnrichedFile';*/
import ImageUploadModal from './components/ImageUploadModal';

//import jwt_decode from 'jwt-decode';


const { Header, Sider, Content } = Layout;
const headerStyle = {
    textAlign: 'left',
    color: '#58595B',
    height: 75,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: '#ffffff',
};
const contentStyle = {
    textAlign: 'center',
    //lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#ffffff',
    class: "main-content-panel"
};
const siderStyle = {
    textAlign: 'left',
    paddingleft: 10,
    //lineHeight: '120px',
    color: '#fff',
    backgroundColor: '#180A48',
};
//const footerStyle = {
//    textAlign: 'center',
//    color: '#fff',
//    backgroundColor: '#ffffff',
//    minHeight: 120,
//};


const speechsdk = require('microsoft-cognitiveservices-speech-sdk')
const ashleyAccessSecurityGroup = '4417bd63-4c77-4ebd-aaa0-67da1b95d0d3';
const ashleyRootAccessSecurityGroup = '7c0237c5-39d5-4bab-be13-3c383a24960e';
const siderWidth = 300;


export default function App() {
    const [personas, setPersonas] = useState([]);
    const [chats, setChats] = useState([]);
    const [currentPersonaName, setcurrentPersonaName] = useState(null);
    const [loadingMessages, setIsLoadingMessages] = useState(false);
    const [loadingChats, setIsLoadingChats] = useState(false);
    const [loadingPersonaList, setLoadingPersonaList] = useState(false);
    const [persona, setPersona] = useState(null);
    const [canEdit, setCanEdit] = useState(false);
    const [messages, setMessages] = useState([]);
    const [chatId, setChatId] = useState(null);
    const [aadAuthToken, setAadAuthToken] = useState(null);
    const [userHasAccess, setUserHasAccess] = useState(false);
    const [showDebugInfo, setShowDebugInfo] = useState(false);
    const [userHasRootAccess, setUserHasRootAccess] = useState(false);
    const [costs, setCosts] = useState({ costForPersona: 0.0, costForUser: 0.0, costForChat: 0.0 });
    const [editPersonaMode, setEditPersonaMode] = useState("edit");
    const [tabs, setTabs] = useState(null);
    const [collapsed, setCollapsed] = useState(false);
    const [selectedSearchAgent, setSelectedSearchAgent] = useState(null);
    const [searchAgentOptions, setSearchAgentOptions] = useState(null);

    const addKeywordsProps = useMemo(() => {
        const formData = new FormData();
        formData.append('persona', currentPersonaName);

        return {
            data: formData,
            action: `${ConfigurationManager.getApiUrl()}Enrichment/AddKeywords`,
            onChange({ file, fileList }) {
                if (file.status !== 'uploading') {
                    console.log(file, fileList);
                }
            },
            defaultFileList: [],
        };
    }
        , [currentPersonaName]);

    const recognizer = useRef(null);

    const recognizerTokenExpiry = useRef(null);


    const mounted = useRef(false);
    const { instance, accounts } = useMsal();

    const [tokenManager, setTokenManager] = useState(null);

    const [playbackManager, setPlaybackManager] = useState(null);

    const [openDataConfig, setOpenDataConfig] = useState(false);    




    const onAuthenticated = useCallback(async () => {

        setLoadingPersonaList(true);

        var localTokenManager = new TokenManager(instance, accounts);
        setTokenManager(localTokenManager);

        var token = await localTokenManager.getAADToken();
        setAadAuthToken(token);

        const hasAccessGroup = await localTokenManager.checkIfTokenContainsRequiredGroup(token, ashleyAccessSecurityGroup);
        setUserHasAccess(hasAccessGroup);

        const hasRootAccessGroup = await localTokenManager.checkIfTokenContainsRequiredGroup(token, ashleyRootAccessSecurityGroup);
        setUserHasRootAccess(hasRootAccessGroup);

        if (token) {
            const tokenRes = await localTokenManager.getSpeechAPIToken(token);

            if (tokenRes.authToken === null) {
                console.error('FATAL_ERROR: ' + tokenRes.error);


                return;
            }

            loadPersonas(token, localTokenManager);
        }

    }, [accounts, instance]);

    useEffect(() => {
        if (persona && persona.Agents) {
            /*var i = 0;*/
            var availableAgents = [];

            for (var agentName in persona.Agents) {
                var agent = persona.Agents[agentName];
                if (agent.SearchProvider !== '') {

                    availableAgents.push(agentName);
                }
            }

            setSearchAgentOptions(availableAgents);
            setSelectedSearchAgent("NotUsed");
        }
    }, [persona]);

    //function onChosenSearchAgentChanged(value) {
    //    setSelectedSearchAgent(value);
    //}

    useEffect(() => {
        var localCanEdit = persona && persona.CanEdit;

        setCanEdit(localCanEdit);

        async function onClickChat(item) {
            setIsLoadingMessages(true);

            if (tokenManager) {
                const token = await tokenManager.getAADToken();

                setChatId(item.key);

                var conversation = getConversation(token, item.key, currentPersonaName);
                conversation.then(async (data) => {

                    setMessages([]);
                    for (let i = 0; i < data.length; i++) {
                        let message = data[i];

                        if (message.debugLogId !== null && message.debugLogId !== undefined && message.debugLogId !== "") {
                            const debugData = await getInferenceChat(token, message.debugLogId);
                            setMessages(prev => [...prev, { ...message, debugData: debugData }]);
                        }
                        else {
                            setMessages(prev => [...prev, { ...message }]);
                        }
                    }

                    setIsLoadingMessages(false);
                }).catch(() => { setIsLoadingMessages(false); });
            }
        }

        var tabsDef = [
            {
                key: '1',
                label: `Chats`,
                children: (
                    <Spin size="medium" spinning={loadingChats} className="message-spinner">
                        <Menu items={chats} style={{
                            width: siderWidth - 20, overflowY: 'auto'
                        }} onClick={onClickChat} theme="dark" className="chat-menu"></Menu>
                    </Spin>),
            }];

        if (localCanEdit) {
            async function onDelete() {
                if (window.confirm(`Are you sure you want to delete ${currentPersonaName}?`)) {
                    const token = await tokenManager.getAADToken();
                    const response = await fetch(`${ConfigurationManager.getApiUrl()}Persona/Delete?name=${currentPersonaName}`, {
                        method: 'DELETE',
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (response.ok) {
                        setcurrentPersonaName(null);
                        loadPersonas(await tokenManager.getAADToken());
                    }
                }
            }


            //add the following tab for users with edit access
            tabsDef.push(
                {
                    key: '2',
                    label: `Config`,
                    children: (
                        <div>
                            <div className="data-configuration-section">
                                {/*<div>*/}
                                {/*    <h3>Pre-Processing Data</h3>*/}
                                {/*    <p>To improve hit rates on search results, you can upload your data and have keywords automatically generated by GPT3.5. You can now download the file and re-upload it in the next section below.</p>*/}
                                {/*    <Upload {...addKeywordsProps} data={{ persona: currentPersonaName }} headers={{ 'Authorization': 'Bearer ' + aadAuthToken }} disabled={!localCanEdit} >*/}
                                {/*        <Button icon={<UploadOutlined />} disabled={!localCanEdit} >Upload</Button>*/}
                                {/*    </Upload>*/}

                                {/*    <DownloadFile persona={currentPersonaName} tokenManager={tokenManager} disabled={!localCanEdit}></DownloadFile>*/}
                                {/*</div>*/}

                                <div>
                                    {/*<Select value={selectedSearchAgent} onChange={onChosenSearchAgentChanged} style={{ width: 280 }} >                                        */}
                                    {/*    {*/}
                                    {/*        searchAgentOptions && Object.values(searchAgentOptions).map(agent => (*/}
                                    {/*            <option value={agent}>{agent}</option>*/}
                                    {/*        ))*/}
                                    {/*    }*/}
                                    {/*</Select>*/}
                                    <IndexFileUpload tokenManager={tokenManager} persona={currentPersonaName} disabled={!localCanEdit} selectedSearchAgent={selectedSearchAgent} ></IndexFileUpload>

                                    <Button onClick={async () => triggerImportOfBlobDataFile(await tokenManager.getAADToken(), currentPersonaName, selectedSearchAgent)} disabled={!localCanEdit || selectedSearchAgent === null}>Import Blob Data</Button>
                                </div>

                                <h3>Data Configuration</h3>

                                <p>You can configure the current persona, changing the prompts used throughout the workflow.</p>

                                <Button type="primary" onClick={() => { setOpenDataConfig(true); setEditPersonaMode("edit") }} disabled={!localCanEdit} >
                                    Configure Persona
                                </Button>

                                <h3>Images</h3>
                                <p>In the system, we have the ability to surface images, to prevent CORS errors, we need to upload them here (or get the CORS policy of the host site updated)</p>
                                <ImageUploadModal personaname={currentPersonaName} tokenManager={tokenManager} disabled={!localCanEdit} />

                                {userHasRootAccess ? (
                                    <Button onClick={onDelete} >Delete Persona</Button>) : (<></>)}
                            </div>
                        </div>
                    ),
                });

            tabsDef.push(
                {
                    key: '3',
                    label: 'Costs',
                    children: (
                        <div className="costs-section">
                            <h3>Monthly Spend</h3>
                            <div>Persona: ${costs.costForPersona.toFixed(2)}</div>
                            <div>User: ${costs.costForUser.toFixed(2)}</div>

                        </div>
                    )
                });
        }


        setTabs(tabsDef);
    }
        , [aadAuthToken, addKeywordsProps, chats, costs.costForPersona, costs.costForUser, currentPersonaName, loadingChats, persona, tokenManager, userHasRootAccess, selectedSearchAgent, searchAgentOptions]);


    useEffect(() => {
        if (persona)
            setShowDebugInfo(persona.ShowDebugInfo);
        else
            setShowDebugInfo(false);
    }, [persona]);

    const getPersona = useCallback(async () => {
        if (currentPersonaName && tokenManager) {
            const aadToken = await tokenManager.getAADToken();
            await getPersonaDetails(aadToken, currentPersonaName).then((data) => {
                setPersona(data);
            });
        }
        else {
            setPersona(null);
        }
    }, [currentPersonaName, setPersona, tokenManager]);

    useEffect(() => {
        getPersona();
    }, [getPersona]);



    useEffect(() => {
        setPlaybackManager(new PlaybackManager(speechsdk, tokenManager));        
    }, [tokenManager]);

    useEffect(() => {
        instance.handleRedirectPromise().then(async (result) => {
            await onAuthenticated();
        });
    }, [instance, onAuthenticated]);

    instance.handleRedirectPromise().catch((error) => {
        if (error.errorMessage !== 'User cancelled the flow') {
            console.log(error);
        }
    });

    const setupVoiceRecognition = useCallback(
        async () => {
            if ((recognizer.current === null || recognizerTokenExpiry.current === null || (recognizer.current && recognizerTokenExpiry.current && Date.now() > recognizerTokenExpiry.current)) && tokenManager) {
                const tokenObj = await tokenManager.getSpeechAPIToken();

                if (tokenObj != null) {
                    const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
                    speechConfig.speechRecognitionLanguage = 'en-US';
                    speechConfig.setProperty(speechsdk.PropertyId.SpeechServiceConnection_EndSilenceTimeoutMs, "10000");

                    const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();
                    recognizer.current = new speechsdk.SpeechRecognizer(speechConfig, audioConfig);
                    recognizerTokenExpiry.current = Date.now() + 300000;
                }
            }
        }, [tokenManager]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;

            const runSetupVoiceRecognition = async () => {
                setupVoiceRecognition();
            };
            runSetupVoiceRecognition();
        }
    }, [playbackManager, setupVoiceRecognition]);


    function loadPersonas(token, localTokenManager) {
        var personasPromise = getPersonas(token);
        personasPromise.then(async (personasData) => {
            if (personasData) {
                var personas = personasData.map(a => { return { value: a.value, label: a.value }; });

                setPersonas(personas);
            }
            else {
                setPersonas(null);
                localTokenManager.expireCachedToken();
                const hasAccessGroup = await localTokenManager.checkIfTokenContainsRequiredGroup(token, '4417bd63-4c77-4ebd-aaa0-67da1b95d0d3');
                setUserHasAccess(hasAccessGroup);
            }
            setLoadingPersonaList(false);
        });
    }

    async function sttFromMic(readyCallback, resultCallback, persona, chatId) {
        await setupVoiceRecognition();

        recognizer.current.sessionStarted = (s, e) => {
            readyCallback(true);
        };

        var text = "";

        await new Promise((resolve) => {
            recognizer.current.recognizeOnceAsync(result => {
                readyCallback(false);

                if (result.reason === ResultReason.RecognizedSpeech) {
                    text = result.text;
                } else {
                    console.info('ERROR: Speech was cancelled or could not be recognized. Ensure your microphone is working properly.');
                }

                resolve();
            });
        });

        return text;
    }



    const wakeKeywordModelPath = `${process.env.PUBLIC_URL}/sydney_keyword.table`;

    //Note, this doesn't work as the SDK doesn't support this yet. https://learn.microsoft.com/en-us/azure/ai-services/speech-service/custom-keyword-basics?pivots=programming-language-javascript
    async function wakeOnKeyword(readyCallback, resultCallback) {
        const tokenObj = await tokenManager.getTokenOrRefresh();
        const speechConfig = speechsdk.SpeechConfig.fromAuthorizationToken(tokenObj.authToken, tokenObj.region);
        speechConfig.speechRecognitionLanguage = 'en-US';

        // Create a keyword recognition model from the specified file
        const model = await speechsdk.KeywordRecognitionModel.fromFile(wakeKeywordModelPath);

        // Create an audio configuration that captures audio from the default microphone
        const audioConfig = speechsdk.AudioConfig.fromDefaultMicrophoneInput();

        // Create a speech recognizer using the subscription key, service region, and audio configuration
        const recognizer = new speechsdk.SpeechRecognizer(
            speechConfig,
            audioConfig
        );

        // Start keyword recognition and wait for the result
        recognizer.recognizeOnceAsync(
            model,
            result => {
                // Check the result reason
                if (result.reason === speechsdk.ResultReason.RecognizedKeyword) {
                    // Keyword was recognized, do something here
                    console.log(`Recognized keyword: ${result.text}`);
                } else {
                    // Keyword was not recognized, handle error here
                    console.error(`Error: ${result.errorDetails}`);
                }

                // Close the recognizer
                recognizer.close();
            },
            error => {
                // Handle error here
                console.error(`Error: ${error}`);
                recognizer.close();
            }
        );
    }


    async function handlePersonaChange(value) {
        if (tokenManager) {
            setIsLoadingChats(true);
            console.log(`selected ${value}`);
            setcurrentPersonaName(value);

            const token = await tokenManager.getAADToken();
            if (token) {
                var chats = await getAllChats(token, value)
                setMenuOptions(chats);
            }
            setIsLoadingChats(false);
        }
    };

    const handleLogout = () => {
        instance.logout();
        setAadAuthToken(null);
    };




    function setMenuOptions(chats) {
        if (chats) {
            var menuChats = chats.map(a => {
                if (a.title) {
                    return {
                        title: `${a.title}-${new Date(a.created).toLocaleDateString('en-GB')} ${new Date(a.created).toLocaleTimeString()}`,
                        label: `${a.title}`,
                        key: a.chatId
                    };
                }
                return undefined;
            }
            );

            menuChats = menuChats.filter(item => item !== undefined);
            setChats(menuChats);
        }
    }

    function costsUpdated(costs) {
        setCosts(costs);
    }

    function updateChatList(chats) {
        setMenuOptions(chats);
    }

    function onAddPersona() {
        setEditPersonaMode("add");
        setOpenDataConfig(true);
    }

async function onSavedPersona() {    
    setOpenDataConfig(false);
    loadPersonas(await tokenManager.getAADToken(), tokenManager);
    await getPersona();
 
}

    const menuTrigger = (
        <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined style={{ fontSize: 25, color: '#ffffff', }} /> : <MenuFoldOutlined style={{ fontSize: 25, color: '#ffffff', }} />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
                color: '#ffffff',
                //fontSize: '30px',
                //width: 100,
                //height: 100,
            }}
        />
    );

    function onMenuCollapse(e) {
        setCollapsed(e);
    }

    const onUserMenuClick = (e) => {
        switch (e.key) {
            case "Logout":
                handleLogout();
                break;
            case "Login":
                instance.loginRedirect();
                break;
            default:
                break;
        }
    };

    const userMenuItems = [
        {
            //label: accounts && accounts.length ? accounts[0].name : 'User',
            key: 'SubMenu',
            icon: <UserOutlined style={{ fontSize: 30 }} />,
            children: [
                {
                    label: 'Logout',
                    key: 'Logout',
                }
            ]
        }
    ];

    const loggedoutUserMenuItems = [
        {
            key: 'Login',
            icon: <UserOutlined style={{ fontSize: 30 }} />,

        }
    ];


    return (
        /*<Div100vh>*/
        <Layout>
            <Header style={headerStyle}>

                <img src="Solera.png" alt="Solera" className="logo" /><div class="title">Ashley, Helpline Bot</div>

                {/*<div>*/}
                {/*    {window.env.REACT_APP_API_URL}*/}
                {/*</div>*/}

                <AuthenticatedTemplate>
                    <div class="user-detail">
                        <Menu mode="horizontal" items={userMenuItems} style={{ width: 80, marginTop: 10 }} onClick={onUserMenuClick} />
                    </div>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <div class="user-detail">
                        <Menu mode="horizontal" items={loggedoutUserMenuItems} style={{ width: 80, marginTop: 10 }} onClick={onUserMenuClick} />
                    </div>

                    {/*<div class="user-detail">*/}
                    {/*    <button className="link-button" onClick={() => instance.loginRedirect()}>Login</button>*/}
                    {/*</div>*/}
                </UnauthenticatedTemplate>
                {/*                <button className="link-button" onClick={handleCheckClaims}>Check Claims</button>*/}
            </Header>

            <AuthenticatedTemplate>
                {
                    aadAuthToken ? (
                        userHasAccess ? (
                            <Layout hasSider>
                                <Sider style={siderStyle} width={siderWidth} className="side-panel" collapsible onCollapse={onMenuCollapse} collapsedWidth={0} trigger={menuTrigger}>
                                    <Spin spinning={loadingPersonaList} >
                                        <Space wrap>
                                            <div>
                                                <Select options={personas} onChange={handlePersonaChange} style={{ width: siderWidth - 60 }} placeholder="Select Persona">
                                                </Select>

                                                {userHasRootAccess ? (
                                                    <Button onClick={onAddPersona}>+</Button>)
                                                    : (<></>)}

                                            </div>
                                            <br />

                                            <Tabs defaultActiveKey="1" items={tabs} type="card" theme="dark" className="tabs" >
                                            </Tabs>
                                        </Space>
                                    </Spin>
                                </Sider>

                                <Content style={contentStyle} className="main-content">
                                    <Spin tip="Loading" size="medium" spinning={loadingMessages} className="message-spinner" style={{ height: "100%" }} >
                                        <SydneyChatHub wakeOnKeyword={wakeOnKeyword} playbackManager={playbackManager} mic={sttFromMic} persona={currentPersonaName} loadedChatMessages={messages}
                                            tokenManager={tokenManager} loadedChatId={chatId} aadAuthToken={aadAuthToken} costsUpdated={costsUpdated} updateChatList={updateChatList} showDebugInfo={showDebugInfo} />
                                    </Spin>
                                </Content>

                                < PersonaConfigurator msalInstance={instance} personaname={currentPersonaName} visible={openDataConfig} tokenManager={tokenManager} disabled={!canEdit} mode={editPersonaMode} onSaved={onSavedPersona} onCanceled={() => setOpenDataConfig(false)} />
                            </Layout>) :
                            (<div className="LoginPrompt">You do not have access to this application. Please contact your administrator.</div>)
                    ) : (<div className="LoginPrompt">Please Login</div>)
                }


            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div className="LoginPrompt">Please Login</div>
            </UnauthenticatedTemplate>

        </Layout >


    );
}