import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider} from "@azure/msal-react";
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';


import { PublicClientApplication } from "@azure/msal-browser";
import ConfigurationManager from './components/ConfigurationManager';


const msalConfig = {
    auth: {
        clientId: "0556b5ae-a2b9-4f11-9d79-534b502684ae",
        authority: "https://login.microsoftonline.com/906c5681-f043-4e2e-a7aa-1ebec40f3d2b",
        redirectUri: ConfigurationManager.getUIUrl()
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true
    }
};

const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <MsalProvider instance={msalInstance}>
        {/*<React.StrictMode>        */}
            <App msalInstance={msalInstance} />        
        {/*</React.StrictMode>*/}
    </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
